import * as React from "react";
const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={259.861}
    height={102.867}
    viewBox="0 0 68.755 27.217"
    {...props}
  >
    <defs>
      <path id="a" d="M322.826-122.088h42.225v23.338h-42.225z" />
    </defs>
    <path
      d="M134.226 134.641c-1.36-.699-2.589-3.3-3.598-7.61-1.967-8.406-1.454-16.07 1.215-18.128 2.74-2.114 12.914-.177 15.978 3.043.92.967 1.113 2.274.66 4.49-1.517 7.428-11.19 19.782-14.255 18.205z"
      style={{
        fill: "#f9cc17",
        fillOpacity: 1,
        strokeWidth: 0.0362039,
      }}
      transform="translate(-103.7 -107.766)"
    />
    <path
      d="M133.928 134.75c-1.488-.945-2.995-4.835-3.95-10.193-1.382-7.76-.624-14.25 1.863-15.957 3.106-2.132 13.759.115 16.418 3.464 2.053 2.586-.986 10.517-6.736 17.583-3.41 4.188-6.144 6.025-7.595 5.103zm1.141-.353c1.085-.176 2.835-1.519 4.745-3.642.46-.51 1.026-1.176 1.035-1.215.005-.02-10.03-15.757-10.412-16.328l-.194-.29-.097.526c-.987 5.36.78 16.18 3.252 19.911.574.866 1.02 1.143 1.67 1.038zm9.131-9.467c3.104-4.903 4.571-9.188 4.012-11.716-.481-2.173-6.399-4.615-11.77-4.857-1.206-.054-3.086.094-3.072.242.004.047 10.54 16.687 10.572 16.696.013.003.128-.16.258-.365zm-1.3-5.767c.258-1.292.214-1.781-.275-3.058a13.67 13.67 0 0 1-.264-.735l-.034-.13-.2.05c-.317.079-.876.122-1.138.087-.234-.03-.248-.027-.514.137-.152.094-.394.2-.545.24-.224.058-.325.113-.584.32-.392.315-.572.331-.614.057-.026-.176-.133-.243-.432-.269-.156-.014-.17-.024-.29-.21-.319-.49-.22-.765.426-1.185.228-.149.353-.271.674-.66.267-.322.482-.542.66-.676l.428-.322c.09-.069.308-.32.486-.559.328-.44.615-.689.758-.656.127.03.15-.439.039-.83-.117-.415-.115-.436.049-.399.183.042.352.175.487.385.128.199.136.202.201.073.033-.064.022-.168-.04-.397l-.083-.31.166.037c.34.078.597.375.803.929.142.383.117.358 1.305 1.265 1.916 1.46 2.832 2.68 2.996 3.988.036.292.032.298-.194.355-1.394.351-2.09.805-3.037 1.982-.74.92-1 1.173-1.286 1.249l-.106.027z"
      style={{
        fill: "#000",
        strokeWidth: 0.0369015,
      }}
      transform="translate(-103.7 -107.766)"
    />
    <g
      aria-label="CavApps"
      style={{
        fontSize: "4.23333px",
        lineHeight: 1.25,
        fontFamily: "sans-serif",
        textAlign: "center",
        whiteSpace: "pre",
        shapeInside: "url(#a)",
        fill: "#fff",
        fillOpacity: 1,
        stroke: "#000",
        strokeWidth: 0.111774,
        strokeMiterlimit: 4,
        strokeDasharray: "none",
      }}
      transform="translate(-1359.633 502.706) scale(4.05283)"
    >
      <path
        d="M337.895-120.13v-.394c0-.398-.266-.665-.664-.665h-1.037c-.394 0-.66.267-.66.665v1.634c0 .398.266.664.66.664h1.037c.398 0 .664-.266.664-.664v-.394h-.656v.36c0 .085-.042.127-.127.127h-.796c-.084 0-.127-.042-.127-.127v-1.567c0-.084.043-.127.127-.127h.796c.085 0 .127.043.127.127v.36zM340.245-118.226v-1.536c0-.398-.267-.665-.66-.665h-.496c-.398 0-.664.267-.664.665v.076h.613v-.085c0-.085.043-.127.127-.127h.34c.084 0 .126.042.126.127v.368a.679.679 0 0 0-.449-.156h-.199c-.398 0-.664.266-.664.664v.004c0 .398.266.665.664.665h.2c.211 0 .389-.076.503-.212l.093.212zm-.74-.529h-.445c-.085 0-.127-.042-.127-.127v-.063c0-.085.042-.127.127-.127h.444c.076 0 .119.038.127.11v.08c0 .085-.042.127-.127.127zM341.976-118.226l.745-2.201h-.613l-.479 1.511-.474-1.511h-.656l.745 2.201zM344.495-121.189h-.804l-.97 2.963h.67l.19-.626h1.003l.19.626h.69zm-.415.698.326 1.067h-.652zM345.719-117.379h.613v-1.003a.658.658 0 0 0 .45.156h.198c.398 0 .665-.266.665-.664v-.872c0-.398-.267-.665-.665-.665h-.199c-.216 0-.39.08-.508.216l-.089-.216h-.465zm1.312-1.503c0 .085-.042.127-.127.127h-.445c-.084 0-.127-.042-.127-.127v-.906c.004-.072.047-.11.127-.11h.445c.085 0 .127.042.127.127zM348.11-117.379h.614v-1.003a.658.658 0 0 0 .449.156h.199c.398 0 .665-.266.665-.664v-.872c0-.398-.267-.665-.665-.665h-.199c-.216 0-.39.08-.508.216l-.089-.216h-.466zm1.313-1.503c0 .085-.043.127-.127.127h-.445c-.084 0-.127-.042-.127-.127v-.906c.004-.072.047-.11.127-.11h.445c.084 0 .127.042.127.127zM351.726-119.572h-.504c-.085 0-.127-.042-.127-.127v-.072c0-.085.042-.127.127-.127h.402c.085 0 .127.042.127.127v.085h.614v-.076c0-.398-.267-.665-.665-.665h-.559c-.393 0-.66.267-.66.665v.012c0 .398.267.665.66.665h.504c.085 0 .127.047.127.131v.068c0 .085-.042.131-.127.131h-.444c-.085 0-.127-.042-.127-.127v-.085h-.614v.077c0 .398.267.664.665.664h.6c.394 0 .661-.266.661-.664v-.017c0-.398-.267-.665-.66-.665z"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 700,
          fontStretch: "normal",
          fontSize: "4.23333px",
          fontFamily: "Purista",
          InkscapeFontSpecification: "&quot",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#fff",
          fillOpacity: 1,
          stroke: "#000",
          strokeWidth: 0.111774,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
        }}
      />
    </g>
  </svg>
);
export default Logo;
